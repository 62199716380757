@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

@font-face {
  font-family: "Presicav";
  src: local("Presicav"),
    url("./fonts/Presicav.otf") format("opentype");
}

h1, h2, h3, li {
  font-family: "Presicav";
  color: white;
}

p {
  font-family: "Open Sans";
  color: white;
}

#menu a.active {
  color: #E5B446;
}

.nav-link:hover, .socials:hover, .release-link:hover {
  transition: all .5s ease-in-out;
  color: #E5B446;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30vh 0 0 0;
  min-height: 100vh;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

.announcement {
  margin: 1rem 0;
  border-radius: 3vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.5rem;
  padding: 0 1rem;
}

.line {
  background-color: #D7585F;
  display: flex;
  flex-direction:row;
  height:50vh;
  width: 5px;
  margin: 1rem 0;
  z-index: 1;
}

.noise{
  background: #8D8CBD;
  background-image: url(./images/noise.png);
}

.gradient-line{
  background: #D7585F;
  background-image: linear-gradient(45deg, #D7585F, #E5B446);
  background-size: 100%;
  background-repeat: repeat;
}

/* .ground {
  width: 100%;
  bottom: 100px;
  mix-blend-mode: multiply;
  transform: translate3d(0,0,0);
}

.ground-shadow {
  position: absolute;
  width: 100%;
  height: 140px;
  border-radius: 50%;
  background: radial-gradient(#800000, transparent), url('./images/shadow_noise.svg');
  background-size: cover;
  filter: contrast(150%) brightness(700%);
} */

.gradient-text, .title {
  background-color: #E5B446;
  background-image: linear-gradient(45deg, #E5B446, #D7585F);
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
